
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoEstado from '@/servicos/Cadastros/Localizacoes/ServicoEstado';

export default defineComponent({
  name: 'SelecionarEstado',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    siglaSelecionada: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    limparSelecao: {
      type: Boolean,
      default: true,
    },
    apresentarSigla: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'update:siglaSelecionada', 'change', 'blur'],
  setup(props, { emit }) {
    const { montaOpcoesComListaConsultaRapida } = useTelaBase();
    const refSelecionarEstado = ref<HTMLElement | null>(null);
    let debounce = 0;
    const selecionarEstado = new ServicoEstado();

    const state = reactive({
      listaEstados: [] as IOption[],
      buscandoDados: false,
      emDigitacao: false,
    });

    async function obterEstadoPorCodigo(valor: any) {
      if (state.emDigitacao) { return; }

      if (props.varios) {
        if (props.codigosSelecionados.length === 0) { return; }
      } else {
        if (!UtilitarioGeral.valorValido(valor)) { return; }
        if (!(valor > 0)) { return; }
        if (props.codigoSelecionado === 0) return;
      }
      let efetuarConsulta = true;

      if (UtilitarioGeral.validaLista(state.listaEstados)) {
        if (props.varios) {
          const listaCodigosConsulta = state.listaEstados.map((c) => c.value);
          const diferencas = props.codigosSelecionados.filter((c) => !listaCodigosConsulta.includes(c));
          if (!UtilitarioGeral.validaLista(diferencas)) {
            efetuarConsulta = false;
          }
        } else {
          const existeLista = state.listaEstados.find((c) => c.value === props.codigoSelecionado);
          if (existeLista !== undefined) {
            efetuarConsulta = false;
          }
        }
      }

      if (!efetuarConsulta) return;

      state.listaEstados = [];

      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        apenasAtivos: false, recursoAssociado: '',
      };

      if (props.varios) {
        parametrosConsultaRapida.filtrarPorVariosCodigos = true;
        parametrosConsultaRapida.valores = valor;
      } else {
        parametrosConsultaRapida.filtrarPorCodigo = true;
        parametrosConsultaRapida.valor = valor;
      }
      const listaEstados = await selecionarEstado.consultaRapida(parametrosConsultaRapida, props.apresentarSigla);
      state.listaEstados = montaOpcoesComListaConsultaRapida(listaEstados);
    }

    async function pesquisarEstado(valorPesquisa: any) {
      state.emDigitacao = true;
      state.buscandoDados = true;
      state.listaEstados = [];

      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida: IParametrosConsultaRapida = {
          valor: valorPesquisa, apenasAtivos: true, recursoAssociado: '', quantidadeRegistros: 50,
        };

        const listaEstados = await selecionarEstado.consultaRapida(parametrosConsultaRapida, props.apresentarSigla);
        state.buscandoDados = false;
        state.listaEstados = montaOpcoesComListaConsultaRapida(listaEstados);
      }, 600);
    }

    function obterEstado():any {
      obterEstadoPorCodigo(props.codigoSelecionado);
      return props.codigoSelecionado;
    }

    const computedSiglaSelecionada = computed({
      get: () => props.siglaSelecionada,
      set: (val: string) => {
        emit('update:siglaSelecionada', val);
      },
    });

    const computedCodigoSelecionado = computed({
      get: () => obterEstado(),
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
        if (props.apresentarSigla) {
          const option = state.listaEstados.find((c) => c.value === val);
          if (option?.label !== undefined && option?.label !== '') {
            computedSiglaSelecionada.value = option?.label.substring(0, 2);
          }
        }
      },
    });

    function obterEstados():number[] {
      obterEstadoPorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterEstados(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    function focus() {
      if (refSelecionarEstado.value) {
        refSelecionarEstado.value.focus();
      }
    }

    function blur() {
      state.emDigitacao = false;
      emit('blur');
    }

    return {
      props,
      state,
      refSelecionarEstado,
      pesquisarEstado,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      change,
      focus,
      blur,
    };
  },
});
